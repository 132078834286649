import { NgModule } from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {AsModuleComponent} from "./pages/as-module/as-module.component";
import {AsWidgetComponent} from "./pages/as-widget/as-widget.component";
import {SetModuleModeGuard} from "./guards/set-module-mode.guard";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: AsModuleComponent,
    canActivate: [SetModuleModeGuard]
  },
  {
    path: 'widget',
    component: AsWidgetComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModuleModule { }
