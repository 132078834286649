import {Component, Input, OnDestroy} from '@angular/core';
import {ICalendarDto} from "@tech/api-clients/calendar";
import {Subject} from "rxjs";
import {selectAvailableCalendars, selectCurrentCalendar} from "../../state/calendar/calendar.selectors";
import {takeUntil} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-calendar-selector',
  templateUrl: './calendar-selector.component.html',
  styleUrls: ['./calendar-selector.component.scss']
})
export class CalendarSelectorComponent implements OnDestroy {

  @Input() private isWidget = false;

  private destroy$ = new Subject<boolean>();

  public availableCalendars: ICalendarDto[];
  public currentCalendar: ICalendarDto;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.store.select(selectAvailableCalendars)
      .pipe(takeUntil(this.destroy$))
      .subscribe(availableCalendars => this.availableCalendars = availableCalendars);

    this.store.select(selectCurrentCalendar)
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentCalendar => this.currentCalendar = currentCalendar);
  }

  public updateCalendarUrl() {
    if (this.currentCalendar && !this.isWidget) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {calendarId: this.currentCalendar.id},
        queryParamsHandling: 'merge'
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
