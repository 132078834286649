import {ICalendarDto, ICalendarEventDto} from "@tech/api-clients/calendar";
import {createReducer, on} from "@ngrx/store";
import {
  addRealtimeCalendarEvent,
  loadAvailableCalendarsSuccess, setCalendarDateRange,
  setCalendarEvents,
  setCalendarLocale, setCalendarUrlConfig,
  setCurrentCalendar, setModuleMode
} from "./calendar.actions";
import {CalendarOptions, EventInput} from "@fullcalendar/core";
import allLocales from "@fullcalendar/core/locales-all";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import {IDateRange} from "../../models/date-range.interface";
import {IUrlConfig} from "../../models/url-config.interface";

export const calendarStateFeatureKey = 'calendarState';

export interface CalendarState {
  availableCalendars: ICalendarDto[],
  currentCalendar: ICalendarDto,
  calendarSelector: boolean,
  currentDateRange: IDateRange,
  urlConfig: IUrlConfig,
  fullCalendarOptions: CalendarOptions,
}

export const initialState: CalendarState = {
  availableCalendars: [],
  currentCalendar: undefined,
  calendarSelector: false,
  currentDateRange: undefined,
  urlConfig: undefined,
  fullCalendarOptions: {
    locales: allLocales,
    timeZone: 'local',
    initialView: '',
    height: '100%',
    headerToolbar: false,
    footerToolbar: false,
    initialDate: new Date(),
    eventSources: [],
    plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
  }
};

export const calendarReducer = createReducer(
  initialState,
  on(setCalendarLocale, (state, {data}): CalendarState => ({
    ...state,
    fullCalendarOptions: {
      ...state.fullCalendarOptions,
      locale: data
    }
  })),
  on(loadAvailableCalendarsSuccess, (state, {data}): CalendarState => ({
    ...state,
    availableCalendars: data
  })),
  on(setCurrentCalendar, (state, {data}): CalendarState => ({
    ...state,
    currentCalendar: data
  })),
  on(setCalendarEvents, (state, {data}): CalendarState => ({
    ...state,
    fullCalendarOptions: {
      ...state.fullCalendarOptions,
      events: [...mapEvents(...data)]
    }
  })),
  on(setCalendarUrlConfig, (state, {data}): CalendarState => {
    const htb = data.htb?.split(';');
    const ftb = data.ftb?.split(';');

    return ({
      ...state,
      urlConfig: data,
      calendarSelector: data.calendarSelector,
      fullCalendarOptions: {
        ...state.fullCalendarOptions,
        initialView: data.view?.trim() ?? state.fullCalendarOptions.initialView,
        headerToolbar: htb && htb.length > 0 ? {
          start: htb[0] ?? '',
          center: htb[1] ?? '',
          end: htb[2] ?? '',
        } : state.fullCalendarOptions.headerToolbar,
        footerToolbar: ftb && ftb.length > 0 ? {
          start: ftb[0] ?? '',
          center: ftb[1] ?? '',
          end: ftb[2] ?? '',
        } : state.fullCalendarOptions.footerToolbar
      }
    });
  }),
  on(setCalendarDateRange, (state, {data}): CalendarState => ({
    ...state,
    currentDateRange: data
  })),
  on(addRealtimeCalendarEvent, (state, {data}): CalendarState => {
    const startDate = new Date(Date.parse(data.start.dateTime));
    if(state.currentDateRange.start > startDate)
      return state;

    const events = [
      ...state.fullCalendarOptions.events as EventInput[],
      ...mapEvents(data)
    ]

    return ({
      ...state,
      fullCalendarOptions: {
        ...state.fullCalendarOptions,
        events: [...events]
      }
    });
  }),
  on(setModuleMode, (state): CalendarState => ({
    ...state,
    fullCalendarOptions: {
      ...state.fullCalendarOptions,
      headerToolbar: {
        start: 'dayGridMonth,timeGridWeek,timeGridDay',
        center: 'title',
        end: 'prev,next'
      }
    }
  })),
)

function mapEvents(...events: ICalendarEventDto[]): EventInput[] {
  return events.map(e => {
    const start = convertUTCDateToLocalDate(e.start?.dateTime ? new Date(Date.parse(e.start.dateTime)) : new Date());
    const end = convertUTCDateToLocalDate(e.end?.dateTime ? new Date(Date.parse(e.end.dateTime)) : new Date(start.toDateString()));
    return {
      id: e.id,
      title: e.subject,
      start,
      end
    }
  });
}

function convertUTCDateToLocalDate(date: Date): Date {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}
