import {createAction, props} from "@ngrx/store";
import {ICalendarDto, ICalendarEventDto} from "@tech/api-clients/calendar";
import {IUrlConfig} from "../../models/url-config.interface";
import {IDateRange} from "../../models/date-range.interface";

export enum CalendarActionTypes {
  SetCalendarLocale = '[Calendar] Set Calendar Locale',
  SetCalendarDateRange = '[Calendar] Set Calendar Date Range',
  LoadAvailableCalendars = '[Calendar] Load Available Calendars',
  LoadAvailableCalendarsSuccess = '[Calendar] Load Available Calendars Success',
  LoadAvailableCalendarsError = '[Calendar] Load Available Calendars Error',
  SetCurrentCalendar = '[Calendar] Set Current Calendar',
  SetCalendarEvents = '[Calendar] Set Calendar Events',
  SetCalendarUrlConfig = '[Calendar] Set Calendar url config',
  AddRealtimeCalendarEvent = '[Calendar] Add Realtime Calendar Event',
  SetModuleMode = '[Calendar] Set Module mode'
}

export const setCalendarLocale = createAction(
  CalendarActionTypes.SetCalendarLocale,
  props<{data: string}>()
);

export const setCalendarDateRange = createAction(
  CalendarActionTypes.SetCalendarDateRange,
  props<{data: IDateRange}>()
);

export const loadAvailableCalendars = createAction(CalendarActionTypes.LoadAvailableCalendars);

export const loadAvailableCalendarsSuccess = createAction(
  CalendarActionTypes.LoadAvailableCalendarsSuccess,
  props<{data: ICalendarDto[]}>()
);

export const loadAvailableCalendarsError = createAction(CalendarActionTypes.LoadAvailableCalendarsError);

export const setCurrentCalendar = createAction(
  CalendarActionTypes.SetCurrentCalendar,
  props<{data: ICalendarDto}>()
);

export const setCalendarEvents = createAction(
  CalendarActionTypes.SetCalendarEvents,
  props<{data: ICalendarEventDto[]}>()
);

export const setCalendarUrlConfig = createAction(
  CalendarActionTypes.SetCalendarUrlConfig,
  props<{data: IUrlConfig}>()
);

export const addRealtimeCalendarEvent = createAction(
  CalendarActionTypes.AddRealtimeCalendarEvent,
  props<{data: ICalendarEventDto}>()
);

export const setModuleMode = createAction(CalendarActionTypes.SetModuleMode);
