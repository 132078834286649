import {Observable} from 'rxjs';

// Core
import {TranslateLoader} from '@ngx-translate/core';

// APIs
import {TranslationsClient} from '@tech/api-clients/modules';

export class ModuleTranslationsLoader implements TranslateLoader {

  constructor(
    private translationsClient: TranslationsClient,
    private moduleCode: string
  ) {
  }

  public getTranslation(lang: string): Observable<any> {
    return this.translationsClient.getTranslationsForModuleAndLanguage(lang, this.moduleCode);
  }

}
