import {Injectable} from '@angular/core';
import {KeycloakService} from "keycloak-angular";
import {HubConnection, HubConnectionBuilder} from "@microsoft/signalr";
import {environment} from "../../environments/environment";
import {CalendarEventDto} from "@tech/api-clients/calendar";
import {Store} from "@ngrx/store";
import {connectError, connectSuccess, disconnected} from "../state/signal-rhub/signalr-hub.actions";
import {addRealtimeCalendarEvent} from "../state/calendar/calendar.actions";

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  // @ts-ignore
  private hubConnection: HubConnection;

  constructor(
    private store: Store,
    private keycloakService: KeycloakService
  ) {}

  public startConnection = async () => {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.calendarApiUrl}/hubs/calendarHub`, {
        accessTokenFactory: () => this.keycloakService.getToken()
      })
      .build();

    const start = async () => {
      try {
        await this.hubConnection.start();
      } catch (err) {
        this.store.dispatch(connectError());
      }
    };

    this.hubConnection.onclose(() => {
      this.store.dispatch(disconnected());
    });

    this.hubConnection.on('SuccessfulRegistration', () => {
      this.store.dispatch(connectSuccess());
    });

    this.hubConnection.on('UnsuccessfulRegistration', () => {
      this.store.dispatch(connectError());
    });

    this.hubConnection.on('ReceiveRealtimeEvent', (data: CalendarEventDto) => {
      this.store.dispatch(addRealtimeCalendarEvent({data: data}));
    });

    await start();
  }

  public async registerRealtimeEventsListenerFor(calendarId: string) {
    await this.hubConnection.invoke("ConnectToCalendarGroup", calendarId);
  }

  public async closeConnection(): Promise<void> {
    this.hubConnection?.off('ReceiveRealtimeEvent');
    this.hubConnection?.onclose(_ => {});
    await this.hubConnection?.stop();
  }
}
