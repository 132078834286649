import {createAction} from "@ngrx/store";

export enum SignalRHubActionTypes {
  Connect = '[SignalR] Connect',
  ConnectSuccess = '[SignalR] Connect Success',
  ConnectError = '[SignalR] Connect Error',
  ConnectRetry = '[SignalR] Connect Retry',
  ConnectRetryLimitReached = '[SignalR] Connect Retry Limit Reached',
  Disconnected = '[SignalR] Disconnected',
  ListenRealtimeEvents = '[SignalR] Listen Realtime Events'
}

export const connect = createAction(SignalRHubActionTypes.Connect);

export const connectSuccess = createAction(SignalRHubActionTypes.ConnectSuccess);

export const connectError = createAction(SignalRHubActionTypes.ConnectError);

export const connectRetry = createAction(SignalRHubActionTypes.ConnectRetry);

export const connectRetryLimitReached = createAction(SignalRHubActionTypes.ConnectRetryLimitReached);

export const disconnected = createAction(SignalRHubActionTypes.Disconnected);

export const listenRealtimeEvents = createAction(SignalRHubActionTypes.ListenRealtimeEvents);
