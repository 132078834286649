import {Component, OnDestroy} from '@angular/core';
import {
  selectCalendarSelector
} from "../../state/calendar/calendar.selectors";
import {takeUntil} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {Subject} from "rxjs";
import {toggleProtocol} from "@tech/intranet-core-protocol";

@Component({
  selector: 'app-as-widget',
  templateUrl: './as-widget.component.html',
  styleUrls: ['./as-widget.component.scss']
})
export class AsWidgetComponent implements OnDestroy {

  private destroy$ = new Subject<boolean>();

  public calendarSelector = false;

  constructor(
    private store: Store,
  ) {
    this.store.select(selectCalendarSelector)
      .pipe(takeUntil(this.destroy$))
      .subscribe(calendarSelector => this.calendarSelector = calendarSelector);
    this.store.dispatch(toggleProtocol({protocolStatus: false}));
  }

  ngOnDestroy() : void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
