export const environment = {
  production: false,
  calendarApiUrl: 'https://test.calendar.intranet.techtitute.com',
  authApiUrl: 'https://test.auth.intranet.techtitute.com',
  modulesApiUrl: 'https://test.module.intranet.techtitute.com',
  coreOrigin: 'https://test.intranet.techtitute.com',
  moduleOrigin: 'https://test.calendar.intranet.techtitute.com',
  moduleCode: 'calendar',
  keycloak: {
    url: 'https://test.login.intranet.techtitute.com/auth',
    realm: 'intranet-test',
    clientId: 'module-calendar',
  }
};
