import {InjectionToken, NgModule} from '@angular/core';
import {StoreModule} from "@ngrx/store";
import {signalrHubReducer, signalrHubStateFeatureKey} from "./signalr-hub.reducer";
import {EffectsModule} from "@ngrx/effects";
import {provideBootstrapEffects} from "../../utils";
import {SignalrHubEffects} from "./signalr-hub.effects";

export const SIGNALR_HUB_EFFECTS = new InjectionToken('SignalR Hub Effects');

@NgModule({
  imports: [
    StoreModule.forFeature(signalrHubStateFeatureKey, signalrHubReducer),
    EffectsModule
  ],
  providers: [
    provideBootstrapEffects(SIGNALR_HUB_EFFECTS, [
      SignalrHubEffects
    ])
  ]
})
export class SignalRHubModule { }
