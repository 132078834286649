import {createReducer, on} from "@ngrx/store";
import {connect, connectError, connectRetry, connectSuccess, disconnected} from "./signalr-hub.actions";
import {ConnectionStatus} from "../../enums/connection-status.enum";

export const signalrHubStateFeatureKey = 'signalRHubState';

export interface SignalRHubState {
  connectionStatus: ConnectionStatus,
  connectionRetryCount: number,
  connectionMaxRetryCount: number
}

export const initialState: SignalRHubState = {
  connectionStatus: ConnectionStatus.DISCONNECTED,
  connectionRetryCount: 0,
  connectionMaxRetryCount: 5,
};

export const signalrHubReducer = createReducer(
  initialState,
  on(connect, (state): SignalRHubState => ({
    ...state,
    connectionStatus: ConnectionStatus.CONNECTING,
    connectionRetryCount: 0
  })),
  on(connectError, disconnected, (state): SignalRHubState => ({
    ...state,
    connectionStatus: ConnectionStatus.DISCONNECTED,
  })),
  on(connectSuccess, (state): SignalRHubState => ({
    ...state,
    connectionStatus: ConnectionStatus.CONNECTED,
    connectionRetryCount: 0
  })),
  on(connectRetry, (state): SignalRHubState => ({
    ...state,
    connectionStatus: ConnectionStatus.CONNECTING,
    connectionRetryCount: state.connectionRetryCount + 1
  })),
)
