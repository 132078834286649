import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType, OnInitEffects} from "@ngrx/effects";
import {Action, Store} from "@ngrx/store";
import {UsersClient} from "@tech/api-clients/auth";
import {KeycloakService} from "keycloak-angular";
import {CoreActionTypes} from "./core.actions";
import {catchError, map, mergeMap, switchMap, tap} from "rxjs/operators";
import {from, of} from "rxjs";
import {KeycloakProfile} from "keycloak-js";
import {TranslateService} from "@ngx-translate/core";
import {coreLanguageChange} from "@tech/intranet-core-protocol";
import {CalendarActionTypes} from "./calendar/calendar.actions";

@Injectable()
export class CoreEffects implements OnInitEffects {

  constructor(
    private store: Store,
    private actions$: Actions,
    private keycloakService: KeycloakService,
    private usersClient: UsersClient,
    private translateService: TranslateService
  ) { }

  ngrxOnInitEffects(): Action {
    return {type: CoreActionTypes.Init};
  }

  setLanguage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActionTypes.Init),
      tap(() => this.translateService.use(this.translateService.getBrowserLang())),
      map(() => ({type: CoreActionTypes.Initialized}))
    );
  });

  loadProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActionTypes.Init),
      switchMap(() => from(this.keycloakService.loadUserProfile())
        .pipe(
          map(result => result as KeycloakProfile)
        )),
      mergeMap(keycloakProfile => this.usersClient.getCurrentUserProfile()
        .pipe(
          map(intranetProfile =>
            ({type: CoreActionTypes.LoadProfileSuccess, keycloakProfile: keycloakProfile, intranetProfile: intranetProfile})),
          catchError(() => of({type: CoreActionTypes.LoadProfileError}))
        ))
    );
  });

  listenCoreLanguageChanges$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(coreLanguageChange),
      map(({currentLanguage}) => ({type:CalendarActionTypes.SetCalendarLocale, data: currentLanguage.name}))
    );
  });

}
