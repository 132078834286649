import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FullCalendarElement} from "@fullcalendar/web-component";
import {Subject} from "rxjs";
import {ICalendarDto} from "@tech/api-clients/calendar";
import {CalendarOptions, DatesSetArg} from "@fullcalendar/core";
import {Store} from "@ngrx/store";
import {CalendarEffects} from "../../state/calendar/calendar.effects";
import {selectLoading} from "../../state/core.selectors";
import {takeUntil} from "rxjs/operators";
import {
  selectAvailableCalendars,
  selectCurrentCalendar, selectFullCalendarOptions
} from "../../state/calendar/calendar.selectors";
import {setCalendarDateRange} from "../../state/calendar/calendar.actions";
import {IDateRange} from "../../models/date-range.interface";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-calendar-wrapper',
  templateUrl: './calendar-wrapper.component.html',
  styleUrls: ['./calendar-wrapper.component.scss']
})
export class CalendarWrapperComponent implements OnInit, OnDestroy {

  @ViewChild('calendar', {static: true}) calendarComponent: FullCalendarElement | undefined;

  @Input() private isWidget = false;

  private destroy$ = new Subject<boolean>();
  private month?: number;

  public loading = true;
  public availableCalendars: ICalendarDto[] = [];
  public currentCalendar: ICalendarDto;
  public calendarOptions: CalendarOptions;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private calendarEffects: CalendarEffects
  ) {
    this.store.select(selectLoading)
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => this.loading = loading);

    this.store.select(selectAvailableCalendars)
      .pipe(takeUntil(this.destroy$))
      .subscribe(availableCalendars => this.availableCalendars = availableCalendars);

    this.store.select(selectCurrentCalendar)
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentCalendar => this.currentCalendar = currentCalendar);

    this.store.select(selectFullCalendarOptions)
      .pipe(takeUntil(this.destroy$))
      .subscribe(calendarOptions => {
        this.calendarOptions = {
          ...calendarOptions,
          datesSet: args => this.handleDatesSet(args)
        };
      });
  }

  ngOnInit(): void {
    this.calendarEffects.setCalendarUrlConfig$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({data}) => {
        if (data.view) {
          this.calendarComponent.getApi().changeView(data.view.trim());
        }
        if(data.month) {
          const now = new Date();
          const firstDayOfMonth = new Date(now.getFullYear(), Number(data.month), 1);
          this.calendarComponent.getApi().gotoDate(firstDayOfMonth);
        }
      });
  }

  private handleDatesSet(data: DatesSetArg) {
    if(!this.isWidget)
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {view: data.view.type},
        queryParamsHandling: 'merge'
      });

    this.store.dispatch(setCalendarDateRange({data: data as IDateRange}));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
