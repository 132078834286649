import {Injectable} from '@angular/core';
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";
import {CoreActionTypes} from "../core.actions";
import {catchError, filter, map, mergeMap} from "rxjs/operators";
import {of} from "rxjs";
import {CalendarActionTypes} from "./calendar.actions";
import {CalendarsClient} from "@tech/api-clients/calendar";
import {
  selectAvailableCalendars,
  selectCalendarDateRange,
  selectCurrentCalendar,
  selectDefaultCalendar
} from "./calendar.selectors";
import {SignalRHubActionTypes} from "../signal-rhub/signalr-hub.actions";
import {Store} from "@ngrx/store";
import {selectIntranetProfile} from "../core.selectors";
import {TranslateService} from "@ngx-translate/core";
import {IUrlConfig} from "../../models/url-config.interface";
import {RouterNavigatedAction, routerNavigatedAction} from "@ngrx/router-store";
import {selectConnectionStatus} from "../signal-rhub/signalr-hub.selectors";
import {ConnectionStatus} from "../../enums/connection-status.enum";
import {IMergedRoute, selectMergedRoute} from "@tech/intranet-core-protocol";

@Injectable()
export class CalendarEffects {

  constructor(
    private actions$: Actions,
    private store: Store,
    private calendarsClient: CalendarsClient,
    private translateService: TranslateService
  ) {
  }

  setCalendarLocale$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActionTypes.Initialized),
      concatLatestFrom(() => of(this.translateService.currentLang)),
      map(([_, currentLocale]) => ({type: CalendarActionTypes.SetCalendarLocale, data: currentLocale}))
    );
  });

  setCalendarUrlConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<RouterNavigatedAction<IMergedRoute>>(routerNavigatedAction),
      map(({payload}) => payload.routerState.queryParams as IUrlConfig),
      map(urlConfig => ({type: CalendarActionTypes.SetCalendarUrlConfig, data: urlConfig}))
    );
  });

  resetCurrentCalendar$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<RouterNavigatedAction<IMergedRoute>>(routerNavigatedAction),
      map(({payload}) => payload.routerState.queryParams as IUrlConfig),
      concatLatestFrom(() => [this.store.select(selectCurrentCalendar), this.store.select(selectDefaultCalendar), this.store.select(selectAvailableCalendars)]),
      filter(([urlConfig, currentCalendar, defaultCalendar]) => !!currentCalendar && (urlConfig.calendarId ? currentCalendar.id !== urlConfig.calendarId : currentCalendar.id !== defaultCalendar.id)),
      map(([urlConfig, _, defaultCalendar, calendars]) => urlConfig.calendarId ? calendars.find(c => c.id === urlConfig.calendarId) : defaultCalendar),
      map((calendar) => ({type: CalendarActionTypes.SetCurrentCalendar, data: calendar}))
    );
  });

  loadProfileSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActionTypes.LoadProfileSuccess),
      map(() => ({type: CalendarActionTypes.LoadAvailableCalendars}))
    );
  });

  loadAvailableCalendars$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CalendarActionTypes.LoadAvailableCalendars),
      mergeMap(() => this.calendarsClient.get()
        .pipe(
          map(calendars =>
            ({type: CalendarActionTypes.LoadAvailableCalendarsSuccess, data: calendars})),
          catchError(() => of({type: CalendarActionTypes.LoadAvailableCalendarsError}))
        ))
    );
  });

  setCurrentCalendar$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CalendarActionTypes.LoadAvailableCalendarsSuccess),
      concatLatestFrom(() => [this.store.select(selectDefaultCalendar), this.store.select(selectMergedRoute), this.store.select(selectAvailableCalendars)]),
      map(([_, defaultCalendar, mergedRoute, calendars]) => mergedRoute.queryParams['calendarId'] ? (calendars.find(c => c.id === mergedRoute.queryParams['calendarId']) ?? defaultCalendar) : defaultCalendar),
      map(calendar => ({type: CalendarActionTypes.SetCurrentCalendar, data: calendar}))
    );
  });

  getCurrentCalendarView$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CalendarActionTypes.SetCurrentCalendar, CalendarActionTypes.SetCalendarDateRange),
      concatLatestFrom(() => [this.store.select(selectCalendarDateRange), this.store.select(selectCurrentCalendar), this.store.select(selectIntranetProfile)]),
      filter(([_, dateRange, currentCalendar]) => !!dateRange && !!currentCalendar),
      mergeMap(([_, dateRange, currentCalendar, intranetProfile]) =>
        this.calendarsClient.getView(
          intranetProfile.userPrincipalId,
          currentCalendar.id,
          dateRange.start.toDateString(),
          dateRange.end.toDateString()
        ).pipe(
          concatLatestFrom(() => this.store.select(selectCurrentCalendar)),
          filter(([view, currentCalendar]) => currentCalendar.id === view.id),
          map(([view, currentCalendar]) => currentCalendar.id === view.id ? ({type: CalendarActionTypes.SetCalendarEvents, data: view.events}) : ({type: CoreActionTypes.SetLoading, data: false}))
        )),
    );
  });

  listenRealtimeEvents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CalendarActionTypes.SetCurrentCalendar),
      concatLatestFrom(() => this.store.select(selectConnectionStatus)),
      filter(([_, connectionStatus]) => connectionStatus === ConnectionStatus.DISCONNECTED),
      map(() => ({type: SignalRHubActionTypes.Connect}))
    );
  });
}
