import {Injectable} from "@angular/core";
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {UsersClient} from "@tech/api-clients/auth";
import {KeycloakService} from "keycloak-angular";
import {listenRealtimeEvents, SignalRHubActionTypes} from "./signalr-hub.actions";
import {delay, map, tap} from "rxjs/operators";
import {CalendarsClient} from "@tech/api-clients/calendar";
import {SignalRService} from "../../services/signal-r.service";
import {selectMaxRetryCount, selectRetryCount} from "./signalr-hub.selectors";
import {SentenceCasePipe} from "../../pipes/sentence-case.pipe";
import {TranslateService} from "@ngx-translate/core";
import {Severity} from "@tech/api-clients/notification";
import {selectCurrentCalendar} from "../calendar/calendar.selectors";
import {sendAppLocalNotification} from "@tech/intranet-core-protocol";

@Injectable()
export class SignalrHubEffects {

  constructor(
    private actions$: Actions,
    private store: Store,
    private keycloakService: KeycloakService,
    private usersClient: UsersClient,
    private calendarsClient: CalendarsClient,
    private signalRService: SignalRService,
    private translateService: TranslateService,
    private sentenceCasePipe: SentenceCasePipe
  ) { }

  connect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalRHubActionTypes.Connect, SignalRHubActionTypes.ConnectRetry),
      tap(() => this.signalRService.startConnection())
    );
  }, {dispatch: false});

  connectErrorOrDisconnected$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalRHubActionTypes.ConnectError, SignalRHubActionTypes.Disconnected),
      tap(() => {
        this.store.dispatch(sendAppLocalNotification({
          data: {
            dismissible: true,
            timeout: 5000,
            severity: Severity.Danger,
            body: this.sentenceCasePipe.transform(this.translateService.instant('messages.error.notificationHubDisconnected'))
          }
        }));
      }),
      concatLatestFrom(() => [
        this.store.select(selectRetryCount),
        this.store.select(selectMaxRetryCount)
      ]),
      delay(5000),
      map(([_, retryCount, maxRetryCount]) => {
        if(retryCount < maxRetryCount)
          return ({type: SignalRHubActionTypes.ConnectRetry});

        return ({type: SignalRHubActionTypes.ConnectRetryLimitReached});
      })
    );
  });

  connectSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalRHubActionTypes.ConnectSuccess),
      map(() => ({type: SignalRHubActionTypes.ListenRealtimeEvents}))
    );
  });

  listenRealtimeEvents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(listenRealtimeEvents),
      concatLatestFrom(() => this.store.select(selectCurrentCalendar)),
      tap(([_, data]) => this.signalRService.registerRealtimeEventsListenerFor(data.id))
    );
  }, {dispatch: false});

}
