import {createAction, props} from "@ngrx/store";
import {KeycloakProfile} from "keycloak-js";
import {UserProfileViewModel} from "@tech/api-clients/auth";

export enum CoreActionTypes {
  Init = '[Core] Init',
  Initialized = '[Core] Initialized',
  LoadProfile = '[Core] Load Profile',
  LoadProfileSuccess = '[Core] Load Profile Success',
  LoadProfileError = '[Core] Load Profile Error',
  SetLoading = '[Core] Set Loading'
}

export const loadProfile = createAction(CoreActionTypes.LoadProfile);

export const loadProfileSuccess = createAction(
  CoreActionTypes.LoadProfileSuccess,
  props<{keycloakProfile: KeycloakProfile, intranetProfile: UserProfileViewModel}>()
);

export const loadProfileFail = createAction(CoreActionTypes.LoadProfileError);

export const setLoading = createAction(
  CoreActionTypes.LoadProfileError,
  props<{data: boolean}>()
);
