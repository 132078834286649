import {createFeatureSelector, createSelector} from "@ngrx/store";
import {CalendarState, calendarStateFeatureKey} from "./calendar.reducer";

const calendarState = createFeatureSelector<CalendarState>(calendarStateFeatureKey);

export const selectAvailableCalendars = createSelector(calendarState, state => state.availableCalendars);
export const selectDefaultCalendar = createSelector(calendarState, state => state.availableCalendars.find(c => c.isDefaultCalendar) || state.availableCalendars[0]);
export const selectCurrentCalendar = createSelector(calendarState, state => state.currentCalendar);
export const selectFullCalendarOptions = createSelector(calendarState, state => state.fullCalendarOptions);
export const selectCalendarDateRange = createSelector(calendarState, state => state.currentDateRange);
export const selectCalendarSelector = createSelector(calendarState, state => state.calendarSelector);
