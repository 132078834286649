import {InjectionToken, NgModule} from '@angular/core';
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {provideBootstrapEffects} from "../../utils";
import {calendarReducer, calendarStateFeatureKey} from "./calendar.reducer";
import {CalendarEffects} from "./calendar.effects";

export const CALENDAR_EFFECTS = new InjectionToken('Calendar Effects');

@NgModule({
  imports: [
    StoreModule.forFeature(calendarStateFeatureKey, calendarReducer),
    EffectsModule
  ],
  providers: [
    provideBootstrapEffects(CALENDAR_EFFECTS, [
      CalendarEffects
    ])
  ]
})
export class CalendarModule { }
