import {APP_BOOTSTRAP_LISTENER, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, InjectionToken, NgModule} from '@angular/core';

import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService} from "keycloak-angular";
import {initializeKeycloak} from "./app-initializer";
import {CALENDAR_API_BASE_URL} from "@tech/api-clients/calendar";
import {environment} from "../environments/environment";
import {AppRoutingModuleModule} from "./app-routing-module.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AUTH_API_BASE_URL} from "@tech/api-clients/auth";
import {TechLoaderModule} from "@tech/components/tech-loader";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {coreReducer, coreStateFeatureKey} from "./state/core.reducer";
import {provideBootstrapEffects} from "./utils";
import {CoreEffects} from "./state/core.effects";
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {SentenceCasePipe} from "./pipes/sentence-case.pipe";
import {SignalRHubModule} from "./state/signal-rhub/signalr-hub.module";
import {IntranetCoreProtocolModule, NgrxRouterModule} from "@tech/intranet-core-protocol";
import {CalendarModule} from "./state/calendar/calendar.module";
import {MODULES_API_BASE_URL, TranslationsClient} from "@tech/api-clients/modules";
import {ModuleTranslationsLoader} from "./loaders/module-translations-loader";
import {TechSelectModule} from "@tech/components/tech-select";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AsModuleComponent} from "./pages/as-module/as-module.component";
import {AsWidgetComponent} from "./pages/as-widget/as-widget.component";
import {CalendarWrapperComponent} from "./components/calendar-wrapper/calendar-wrapper.component";
import {TechScrollbarModule} from "@tech/components/tech-scrollbar";
import {TechCardModule} from "@tech/components/tech-card";
import {CalendarSelectorComponent} from "./components/calendar-selector/calendar-selector.component";
import {FullCalendarModule} from "@fullcalendar/angular";

export const CORE_EFFECTS = new InjectionToken('Core Effects');

@NgModule({
  declarations: [
    AppComponent,
    SentenceCasePipe,
    AsModuleComponent,
    AsWidgetComponent,
    CalendarWrapperComponent,
    CalendarSelectorComponent
  ],
  imports: [
    BrowserAnimationsModule,
    KeycloakAngularModule,
    HttpClientModule,
    AppRoutingModuleModule,
    FormsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    IntranetCoreProtocolModule.forRoot({
      coreOrigin: environment.coreOrigin,
      moduleOrigin: environment.moduleOrigin,
      moduleCode: environment.moduleCode,
      coreRoutes: []
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (translationsClient: TranslationsClient) => new ModuleTranslationsLoader(translationsClient, environment.moduleCode),
        deps: [TranslationsClient, HttpClient]
      }
    }),
    StoreModule.forRoot({
      [coreStateFeatureKey]: coreReducer
    }),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production, name: 'Calendar Store'}),
    NgrxRouterModule,
    SignalRHubModule,
    CalendarModule,
    EffectsModule.forRoot(),

    TechLoaderModule,
    TechSelectModule,
    TechScrollbarModule,
    TechCardModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: APP_BOOTSTRAP_LISTENER,
      useFactory: (translateService: TranslateService) => () => translateService.use(translateService.getBrowserLang()),
      multi: true,
      deps: [TranslateService, HttpClient]
    },
    {
      provide: CALENDAR_API_BASE_URL,
      useValue: environment.calendarApiUrl
    },
    {
      provide: AUTH_API_BASE_URL,
      useValue: environment.authApiUrl
    },
    {
      provide: MODULES_API_BASE_URL,
      useValue: environment.modulesApiUrl
    },
    provideBootstrapEffects(CORE_EFFECTS,[
      CoreEffects
    ]),
    SentenceCasePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
