import {KeycloakProfile} from "keycloak-js";
import {UserProfileViewModel} from "@tech/api-clients/auth";
import {createReducer, on} from "@ngrx/store";
import {
  loadProfile,
  loadProfileFail,
  loadProfileSuccess, setLoading
} from "./core.actions";
import {
  connect,
  connectError,
  connectRetry,
  connectRetryLimitReached,
  connectSuccess, disconnected
} from "./signal-rhub/signalr-hub.actions";
import {
  loadAvailableCalendars,
  loadAvailableCalendarsError,
  loadAvailableCalendarsSuccess, setCalendarDateRange, setCalendarEvents, setCurrentCalendar
} from "./calendar/calendar.actions";

export const coreStateFeatureKey = 'coreState';

export interface CoreState {
  keycloakProfile: KeycloakProfile,
  intranetProfile: UserProfileViewModel,
  loading: boolean;
}

export const initialState: CoreState = {
  keycloakProfile: null,
  intranetProfile: null,
  loading: false
};

export const coreReducer = createReducer(
  initialState,
  on(loadProfile,
    loadAvailableCalendars,
    connect,
    connectRetry,
    setCurrentCalendar,
    setCalendarDateRange,
    (state): CoreState => ({
    ...state,
    loading: true
  })),
  on(loadProfileSuccess,
    loadProfileFail,
    loadAvailableCalendarsSuccess,
    loadAvailableCalendarsError,
    connectSuccess,
    connectError,
    connectRetryLimitReached,
    disconnected,
    setCalendarEvents,
    (state): CoreState => ({
      ...state,
      loading: false
    })),
  on(loadProfileSuccess, (state, {keycloakProfile, intranetProfile}): CoreState => ({
    ...state,
    keycloakProfile: keycloakProfile,
    intranetProfile: intranetProfile
  })),
  on(setLoading, (state, {data}): CoreState => ({
    ...state,
    loading: data
  })),
)
