<div class="app-grid" techScrollbars>
  <tech-card>
    <tech-card-header><h2>{{'general.availableCalendars' | translate | titlecase}}</h2></tech-card-header>
    <tech-card-content>
      <app-calendar-selector [isWidget]="false"></app-calendar-selector>
    </tech-card-content>
  </tech-card>
  <tech-card class="calendar-card">
    <tech-card-content>
      <app-calendar-wrapper [isWidget]="false"></app-calendar-wrapper>
    </tech-card-content>
  </tech-card>
</div>
